// 941px breakpoint for mobile
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
.horizontalScroll {
  &::-webkit-scrollbar-track {
    background-color: #2e2d2a; /* Light grey background for the track */
    box-shadow: none; /* Remove any default shadows */
  }

  /* Styles the scrollbar thumb (the part that is draggable) */
  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Darker grey for the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    box-shadow: none; /* Remove any default shadows */
  }

  /* Styles the scrollbar itself (overall part, including thumb and track) */
  &::-webkit-scrollbar {
    height: 4px; /* Height of the horizontal scrollbar */
    box-shadow: none; /* Remove any default shadows */
  }
}
@keyframes slideAndReset {
  0% {
    transform: translate(0, 0);
    opacity: 1; /* Fully visible */
  }
  50% {
    transform: translate(100px, -100px);
    opacity: 0; /* Fade out while moving right */
  }
  51% {
    transform: translate(-100px, 100px);
    opacity: 0; /* Invisible while resetting to the left */
  }
  100% {
    transform: translate(0, 0);
    opacity: 1; /* Fade back in */
  }
}
:root {
  --brand-gold: radial-gradient(
    458.07% 144.86% at 13.25% 21.87%,
    #f4e0a3 0%,
    #dcbc65 37.37%,
    #ca9f43 63.89%,
    #fef0a0 79.39%,
    #8e5f1e 100%
  );
  --transparent-white: rgba(255, 255, 255, 0.04);
  --text-primary: #fff;
  --text-tertiary: #969696;
}
@import url("https://fonts.cdnfonts.com/css/conthrax");
@import url("https://fonts.cdnfonts.com/css/telegraf");
/* font-family: 'Telegraf', sans-serif; */
/* font-family: 'Conthrax', sans-serif; */
/* @font-face {
    font-family: "Telegraf";
    src: url("./assets/fonts/PPTelegraf-Regular.otf") format("otf");
} */

/* @font-face {
    font-family: "Conthrax";
    src: url("./assets/fonts/conthrax-sb.otf") format("otf");
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: var(--Brand-Dark, #111);
  background: #111;
  color: #fff;
  width: 100vw;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
body.no-scroll {
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Mui code */
.MuiTabs-root {
  .MuiTabs-indicator {
    background-color: var(--Brand-Gold, #f4e0a3);
  }
  .MuiButtonBase-root {
    color: var(--Text-Inactive, #707070);
    font-family: "Conthrax", sans-serif;
    padding-left: 0;
    padding-right: 0;
    margin-right: 30px;
    &.Mui-selected {
      color: #fff;
    }
  }
}

.MuiOutlinedInput-root {
  padding: 3px;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 32px;
    border: 1px solid var(--Lines-Divider, #383838) !important;
  }
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--Brand-Gold, #f4e0a3) !important;
    }
  }
}
.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.64) !important;
}

// paddngs
.marginborderboxx {
  margin-left: 20px;
  margin-right: 20px;
}
.paddingborderboxx {
  padding-left: 20px;
  padding-right: 20px;
}
.paddingscbox {
  padding: 80px 80px 160px;
  @media screen and (max-width: 941px) {
    padding: 40px 40px 80px;
  }
  @media screen and (max-width: 500px) {
    padding: 40px 15px 80px;
  }
}
.paddinglayoutx {
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 941px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  // @media screen and (max-width: 691px) {
  //   padding-left: 30px;
  //   padding-right: 30px;
  // }
  @media screen and (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.paddinglayouty {
  padding-top: 30px;
  padding-bottom: 30px;
}

.paddingsclayoutx {
  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 941px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.paddingsclayouty {
  padding-top: 30px;
  padding-bottom: 30px;
}

.marginsclayouty1 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.slick-slider {
  display: flex;
  width: 100%;
}
.slick-arrow {
  border-radius: 32px;
  border: 1.5px solid var(--Lines-Divider, #383838) !important;
  background: var(--background-surface-1, #232323) !important;
  width: 64px !important;
  height: 64px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  cursor: pointer;
  &::before {
    display: none;
  }
  font-size: 30px;
  color: #fff !important;
}
.MuiMenu-paper {
  background: #2e2d2a !important;
  border-radius: 12px !important;
  .MuiMenu-list {
    padding: 0 !important;
    .MuiMenuItem-root {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      color: #fff !important;
      padding-top: 15px;
      padding-bottom: 15px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.MuiSnackbar-root {
  .MuiPaper-root {
    background: var(--background-surface-1, #232323);
    border-radius: 32px;
    border: 2px solid #383838;
    padding: 5px 24px;
  }
}

// Loanify specific css
.wrappedbutton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  background: var(--Transparent-White, rgba(255, 255, 255, 0.04));
  padding: 3px;
  padding-left: 24px;
  .left {
    margin-right: 30px;
    h3 {
      color: var(--Text-Tertiary, #969696);

      /* S1/Regular */
      font-family: Telegraf;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin: 0;
      margin-bottom: 6px;
    }
    h4 {
      color: var(--Text-Primary, #fff);
      font-family: Telegraf;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
      margin: 0;
    }
  }
  .right {
  }
}
